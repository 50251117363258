import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useCallback, useEffect } from "react";
import { RootState, store } from "../libs/store";
import { useSelector } from "react-redux";
import { setUser } from "../features/userSlice";
import userApi from "../api/user";
import { httpRequestErrors } from "../utils/errors";
import PrivateLayout from "components/Layout/PrivateLayout";
import { Path, routes } from "./route";

const PrivateRoute = ({ children, isRaw = false }: any) => {
  const navigate = useNavigate();

  const auth = useAuth();
  const location = useLocation();
  const isAuthenticated = auth.isAuthenticated();
  const user = useSelector((state: RootState) => state.user.user);
  // get current router path
  const { pathname } = useLocation();

  console.log("pathname", pathname);

  const { dispatch } = store;

  useEffect(() => {
    if (isAuthenticated && !user.id) {
      getMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user?.id]);

  const isPrivateRoute = routes.some((route) => {
    const regex = new RegExp(`^${route.path.replace(/:\w+/g, "[^/]+")}$`);
    return regex.test(pathname) && route.isPrivate;
  });

  const getMe = useCallback(async () => {
    try {
      const user = await userApi.getMe();
      dispatch(setUser(user));
    } catch (error: any) {
      if (error.response?.status === 401) {
        localStorage.clear();
        navigate(`${Path.SignIn}?redirect=${!isPrivateRoute ? "" : pathname}`);
        return;
      }
      httpRequestErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isAuthenticated) {
    return isRaw ? children : <PrivateLayout>{children}</PrivateLayout>;
  }
  return (
    <Navigate
      to={`${Path.SignIn}?redirect=${!isPrivateRoute ? "" : pathname}`}
      state={{ from: location }}
    />
  );
};

export default PrivateRoute;
